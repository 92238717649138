import cx from 'classnames'
import { GatsbyImage } from 'gatsby-plugin-image'
// @ts-ignore
import { getGatsbyImageData } from 'gatsby-source-sanity'
import React, { useState, useEffect } from 'react'

const sanityConfig = {
  projectId: process.env.GATSBY_SANITY_PROJECT_ID,
  dataset: process.env.GATSBY_SANITY_DATASET,
}

export const Image = ({
  imageId,
  className,
  width,
  height,
  alt,
  src,
  style,
  imgStyle,
  objectFit,
  objectPosition,
  loading,
}: {
  imageId?: string
  width?: number
  height?: number
  alt?: string
  className?: string
  src?: string
  style?: object
  imgStyle?: object
  objectFit?: 'none' | 'contain' | 'fill' | 'cover' | ' ' | undefined
  objectPosition?: string
  loading?: 'eager' | 'lazy' | 'auto' | undefined
}) => {
  const [loaded, setLoaded] = useState(false)
  let gatsbyImageData

  if (imageId && !/gif/.test(imageId)) {
    gatsbyImageData = getGatsbyImageData(
      imageId,
      {
        width: width || 2400,
        height: height || undefined,
      },
      sanityConfig
    )
  }

  return (
    <figure className={cx(className)}>
      {gatsbyImageData && imageId && !/svg/.test(imageId) ? (
        <GatsbyImage
          image={gatsbyImageData}
          alt={alt}
          defaultFadeIn={600}
          style={style}
          imgStyle={imgStyle}
          objectFit={objectFit ? objectFit : 'cover'}
          objectPosition={objectPosition}
          loading={loading}
        />
      ) : (
        <img
          alt={alt}
          src={src ? src : fluidProps && fluidProps.src}
          loading="lazy"
          className={cx('x', 'fade-in-on-load', {
            'is-loaded': loaded,
          })}
          onLoad={() => {
            setLoaded(true)
          }}
          style={style}
        />
      )}
    </figure>
  )
}

export default Image
